import { Component, Prop, Vue } from 'vue-property-decorator';
import { TrCallback } from '../../src/type'

@Component
export default class HasWarning extends Vue {
    dialogWarning = false

    callBackWarningOk: TrCallback | null = null
    callBackWarningCancel: TrCallback | null = null
    contentWarning: string | null = null
    labelOkWarning: string | null = null
    labelCancelWarning: string | null = null

    openWarning (content: string, callbackOk?: TrCallback | null, callbackCancel?: TrCallback | null, labelOk?: string | null, labelCancel?: string | null) {
        this.callBackWarningOk = callbackOk ? callbackOk : null
        this.callBackWarningCancel = callbackCancel ? callbackCancel : null
        this.labelOkWarning = labelOk ? labelOk : null
        this.labelCancelWarning = labelCancel ? labelCancel : null
        this.contentWarning = content
        this.dialogWarning = true
      }
    
}