import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { getBlockCount, getContractStorage, getGetInBigMapKeysIn, getTokenBalanceOfUserMinimal } from '../apiCaller/tzktApi'
import { getIpfsPath, getLvlWithXp, getReqForLvl, detectMobile, getInAttributes } from '../utils/utilities'
import { TrooperzMetadata, TrooperzApiResult, MutableAttributes, TrooperzBoxParams, 
    CrowdsaleContractStorage, Family, TrooperzInfo, MissionMode, ModeInfo, TrainingConfig, MissionConfig, WithKey, 
    TrooperzInfoMission, TokenContractStorage, TokenContractStorageNumber, RewardsInfo, StatsFilter, TbotFilterMode, DeviceType, BigMapSimpleTzkt, TrooperzAttributes } from '@/type';

import { getTrooperzList } from '@/apiCaller/trooperzApiCaller';
import axios from 'axios';
import { Config } from 'config/config_type';
import { calcTrooperzRewards } from '@/utils/rewardsCalculator';


@Component
export default class TrooperzData extends Vue {
    loadingInit = false
    toRevealLoaded = false

    data: TrooperzBoxParams[] = []

    missionList: {[key: number]: MissionConfig} = {}

    currentAddress: string | null = null
    currentBlock: number | null = null

    trainingRoomConfig: TrainingConfig | null = null

    selectedFamily: Family | null = null


    loading = false

    trooperzModes: {[key: number]: ModeInfo} = {}
    trooperzEnergy: {[key: number]: number} = {}
    trooperzXp: {[key: number]: number} = {}
    trooperzAttributes: {[key: number]: TrooperzAttributes} = {}
    toReveal: number[] = []

    infoTokenContract: TokenContractStorageNumber | null = null

    rewardsInfos: {[key: number]: RewardsInfo} = {}


    intervalCountId: number | null = null


    async initPage () {
        this.loadingInit = true
        await this.getAddress()
        await this.getTrooperz()
        await this.$nextTick()

        const tokenids = this.data.map(item => item.token_id)

        const revealedTokenIds = this.data.filter((item) => {
            const revealed = getInAttributes('revealed', item.metadata?.attributes || [])
            return revealed !== 'false'
        }).map(item => item.token_id)

        if (!tokenids.length) {
            console.warn('User has no trooperz!')
            this.loadingInit = false
            return 
        }
        await this.getModeTrooperz(tokenids)
        await this.getStorageTokenInfo()
        await this.getEnergyTrooperz(tokenids)
        await this.getXpTrooperz(tokenids)
        

        // get attributes in big map tzkt api for fresh data
        // remove this when the original data is fixed
        await this.getAndReplaceMutablesAttributes(revealedTokenIds)

        
        const config = this.$config
        if (config) {
            this.currentBlock = await getBlockCount(config.networkTzkt)
            const result = await getContractStorage<TrainingConfig>(config.networkTzkt, config.trainingRoomAddress, ['config'])
            this.trainingRoomConfig = result

            if (this.currentBlock) {
                this.getRewardsTrooperz(this.currentBlock, config, this.trainingRoomConfig)
                this.setIntervalBlockCount()
            }   
        }
        this.loadingInit = false
    }

    async getAddress () {
        const activeAccount = await this.$wallet.client.getActiveAccount()
        if (activeAccount?.address) {
            const address = activeAccount.address
            this.currentAddress = address
        } else {
            this.currentAddress = null
        }
    }

    async loadToReveal () {
        if (this.$config) {
            this.toRevealLoaded = false
            const storage = await getContractStorage<CrowdsaleContractStorage>(this.$config.network, this.$config.marketplaceContractAddress)
            this.toReveal = storage.to_reveal
            this.toRevealLoaded = true
        }
    }

    openLoadingTrooperz() {
        console.warn('Not implemented')
    }

    async getTrooperz () {

        if (this.currentAddress && this.$config) {
            this.loading = true
            this.data = []
            const result = await getTokenBalanceOfUserMinimal(this.currentAddress, 
                                                        this.$config?.networkTzkt,   
                                                        this.$config?.tokenContractAddress)

            this.data = result.data.map((item) => {
                return {
                    token_id: item,
                    metadata: null
                }
            })
            this.$nextTick(() => {
                this.openLoadingTrooperz()
            })

            const tokenids = this.data.map(item => Number(item.token_id))
            const apiArgs: {[key: string]: any} = {id_list: tokenids.join(',')}
            if (!tokenids.length) {
                console.warn('No trooperz!')
                this.loading = false
                return
            }
            const search = null

            if (this.selectedFamily) {
                apiArgs.family = this.selectedFamily
            }

            const resultAllTokenInfo = await getTrooperzList(500, 0, search, apiArgs)
            const allTokenInfo = resultAllTokenInfo.data
            const allTokenInfoTokenids = allTokenInfo.map(item => item.token_id)

            if (!this.selectedFamily) {
                tokenids.forEach(tokenId => {
                    if (!allTokenInfoTokenids.includes(tokenId)) {
                        allTokenInfo.push({
                            token_id: tokenId,
                            revealed: false
                        } as TrooperzApiResult)
                    }
                })
            }

            for (const tokenInfo of this.data) {
                const tokenId = Number(tokenInfo.token_id)
                //let metadata = await getTrooperzMetadataWithContract(tokenContract, tokenId)
                const result = await this.getTrooperzInfo(tokenId, allTokenInfo)
                if (result) {
                    if (result.metadata) {
                        tokenInfo.metadata = result.metadata
                    }

                    if (result.apiResult && result.apiResult.upgradable_attributes) {
                        tokenInfo.upgradablesAttributes = result.apiResult.upgradable_attributes
                    }

                    if (result.apiResult && result.apiResult.rank) {
                        tokenInfo.rank = result.apiResult.rank
                    }
                }
            }

            this.loading = false
        }
    }

    async getTrooperzInfo (tokenId: number, resultApi: TrooperzApiResult[]): Promise<{metadata: TrooperzMetadata|null, apiResult: TrooperzApiResult} | null> {
        const result: TrooperzApiResult[] = resultApi.filter(item => item.token_id == tokenId)
        if (!result.length) {
            console.warn('Can not find trooperz')
        } else {
            const trpResult = result[0]
            let ipfsHash = trpResult.ipfs_hash
            if (!trpResult.ipfs_hash || this.toReveal.includes(tokenId)) {
                ipfsHash = 'QmamqDxvbpbPdhQE4dtv6CYC4HkyhP2Qmy5sZKqZj9U9xT'
            }
            const path = this.getIpfsPath('ipfs://' + ipfsHash)
            const data = await axios.get(path)
            const metadata = data.data
            metadata.token_id = tokenId
            return {
                metadata: metadata,
                apiResult: trpResult
            }
        }
        return null
    }

    getIpfsPath (ipfs: string) {
        return getIpfsPath(ipfs)
    }

    async getModeTrooperz (trooperzId: (number | string)[]) {
        const config = this.$config
        if (config) {
            const trainingRoomAddresses = [...config.oldContracts, config.trainingRoomAddress]
            const usedMissionIds: number[] = []
            for (const address of trainingRoomAddresses) {
                const result = await getGetInBigMapKeysIn<TrooperzInfo>(config.networkTzkt, address, 'ledger', trooperzId)
                result.forEach((item) => {
                    const mode = Object.keys(item.value)[0] as keyof TrooperzInfo
                    let startBlock 
                    let missionId = null
                    if (mode === 'mission') {
                        startBlock = item.value[mode].start_block
                        if (address === config.trainingRoomAddress) {
                            missionId = Number((item.value as TrooperzInfoMission)[mode].mission_id)
                            usedMissionIds.push(missionId)
                        }
                    } else {
                        startBlock = item.value[mode]
                    }
                    this.$set(this.trooperzModes, Number(item.key), {
                        mode: mode,
                        startBlock: startBlock,
                        missionId: missionId
                    })
                })
            }

            if (usedMissionIds) {
                await this.getMissionList(usedMissionIds)
            }
        }
    }

    async getMissionList (missionIds: (number | string)[]) {
        const config = this.$config
        if (config && missionIds.length) {
            const result = await getGetInBigMapKeysIn<string>(config.networkTzkt, config.trainingRoomAddress, 'mission_config', missionIds)
            result.forEach((item) => {
                this.$set(this.missionList, Number(item.key), item.value)
            })
        }
    }

    async getStorageTokenInfo () {
        const config = this.$config
        if (config) {
            const result = await getContractStorage<TokenContractStorage>(config.networkTzkt, config.tokenContractAddress)
            this.infoTokenContract = {
                max_energy: Number(result.max_energy),
                max_xp: Number(result.max_xp),
                max_stats: Number(result.max_xp)
            }
        }
    }

    async getEnergyTrooperz (trooperzId: (number | string)[]) {
        const config = this.$config
        if (config && trooperzId.length) {
            const result = await getGetInBigMapKeysIn<string>(config.networkTzkt, config.tokenContractAddress, 'energy_map', trooperzId)
            result.forEach((item) => {
                this.$set(this.trooperzEnergy, Number(item.key), Number(item.value))
            })
        }
    }


    async getXpTrooperz (trooperzId: (number | string)[]) {
        const config = this.$config
        if (config && trooperzId.length) {
            const result = await getGetInBigMapKeysIn<string>(config.networkTzkt, config.tokenContractAddress, 'xp_map', trooperzId)
            result.forEach((item) => {
                this.$set(this.trooperzXp, Number(item.key), Number(item.value))
            })
        }
    }

    async getAttributesTrooperz (trooperzId: (number | string)[]) {
        const config = this.$config
        if (config && trooperzId.length) {
            const result = await getGetInBigMapKeysIn<TrooperzAttributes>(config.networkTzkt, config.tokenContractAddress, 'trooperz_attributes', trooperzId)
            result.forEach((item) => {
                const mutablesAttributes: MutableAttributes = {
                    fire_arms: Number(item.value.mutable_attributes.fire_arms),
                    close_combat: Number(item.value.mutable_attributes.close_combat),
                    hacking: Number(item.value.mutable_attributes.hacking),
                    trickery: Number(item.value.mutable_attributes.trickery)
                }

                item.value.mutable_attributes = mutablesAttributes

                this.$set(this.trooperzAttributes, Number(item.key), item.value)
            })
        }
    }   

    async getAndReplaceMutablesAttributes (tokenids: (number | string)[]) {
        await this.getAttributesTrooperz(tokenids)

        for (const d of this.data) {
            if (!this.trooperzAttributes[Number(d.token_id)]) {
                continue
            }
            d.upgradablesAttributes = this.trooperzAttributes[Number(d.token_id)].mutable_attributes
        }
    }
    
    async getRewardsTrooperz (block: number, config: Config, trainingConfig: TrainingConfig) {
        if (!this.infoTokenContract) {
            console.warn('[YourTrooperz] Can not find info contract, can not calc rewards!')
            return
        }
        const infoContract = this.infoTokenContract as TokenContractStorageNumber

        this.data.forEach(item => {
            const tokenId = Number(item.token_id)
            const mode = this.trooperzModes[tokenId]

            if (!mode) {
                // console.warn('[YourTrooperz] No mode info for trooperz ' + tokenId)
                return
            }
            const startBlock = mode.startBlock
            const energy = this.trooperzEnergy[tokenId]
            const xp = this.trooperzXp[tokenId]
            const maxXp = infoContract.max_xp
            const maxEnergy = infoContract.max_energy
            let mission: WithKey<MissionConfig> | null = null
            if (mode.mode === 'mission' && mode.missionId) {
                mission = this.getMissionConfigWithKey(mode.missionId)
            }

            const isUnique = this.isUnique(tokenId)
            const nbDigitTcoin = config.nbDigitTcoin
            const rewards = calcTrooperzRewards(mode.mode, startBlock, block, trainingConfig, energy, xp, maxEnergy, maxXp, mission, !!isUnique, nbDigitTcoin)

            if (rewards) {
                this.$set(this.rewardsInfos, tokenId, rewards)
            }
        })
    }

    setIntervalBlockCount () {
        const config = this.$config
        if (config && !this.intervalCountId) {
            this.intervalCountId = window.setInterval(async () => {
                this.currentBlock = await getBlockCount(config.networkTzkt)
                if (this.currentBlock && this.trainingRoomConfig) {
                    this.getRewardsTrooperz(this.currentBlock, config, this.trainingRoomConfig)
                }
            }, 20000)
        }
    }

    getMissionConfigWithKey (missionId: number): WithKey<MissionConfig> {
        return {
            ...this.missionList[missionId],
            key: String(missionId)
        }
    }

    getAllMissionConfigWithKey (): Array<WithKey<MissionConfig>> {
        return Object.keys(this.missionList).map((item) => {
            return this.getMissionConfigWithKey(Number(item))
        })
    }


    isUnique (trooperzId: number) {
        const config = this.$config
        return config && config.uniqueTrooperz.includes(trooperzId)
    }


    beforeDestroy () {
        if (this.intervalCountId) {
            clearInterval(this.intervalCountId)
        }
    }

}
