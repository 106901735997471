




























































































































































import { MutableAttributes, TrCallback, MutableAttributesName, AllStats } from '@/type';
import { Component, Prop, Vue, PropSync, VModel } from 'vue-property-decorator';
import StatsBarFull from '@/components/StatsBarFull.vue'

export type CustomStyles = Partial<Record<AllStats, Record<string, any>>>
export type SideText = Record<AllStats, [string | number, string | number]>

  @Component({
      components: {
        StatsBarFull
      }
  })
  export default class StatsTrooperzBox extends Vue {
    @Prop()
    maxStats!: number

    @Prop()
    stats!: MutableAttributes

    @Prop()
    energyPure!: number

    @Prop()
    xpPure!: number

    @Prop()
    energy!: number

    @Prop()
    energyInfo!: string

    @Prop()
    maxEnergy!: number

    @Prop()
    xpCurrentLvl!: number

    @Prop()
    xpStatsMax!: number

    @Prop()
    lvl!: number

    @Prop()
    maxLvl!: number

    @Prop({ default: 800 })
    startAfter!: number

    @Prop({ default: () => {
        return {}
    }})
    customStyles!: CustomStyles

    @Prop({ default: () => {
        return {}
    }})
    sideText!: SideText

    @Prop()
    sideTextOn!: boolean
  }
  