import { Component, Prop, Vue } from 'vue-property-decorator';
import { Accessors } from 'vue/types/options';

@Component
export default class HasNotification extends Vue {
    openNotification(text: string, title = 'Hey Trooperz!', color  = 'dark', position = null, duration = 10000) {
        const noti = this.$vs.notification({
          duration: duration,
          progress: 'auto',
          color: color,
          position,
          title: title,
          text: text
        })
  }
}


