


























import { TrCallback } from '@/type';
import { Component, Prop, Vue, PropSync, VModel } from 'vue-property-decorator';
  
  @Component({
      components: {}
  })
  export default class BaseDialog extends Vue {
    @VModel()
    dialog!: boolean

    @Prop()
    content!: string

    @Prop({default: 'Hey Trooperz!'})
    title!: string

    @Prop({default: 'ok'})
    labelOk!: string

    @Prop({default: 'cancel'})
    labelCancel!: string

    @Prop()
    callbackOk!: TrCallback

    @Prop()
    callbackCancel!: TrCallback

    onOk () {
        if (this.callbackOk) {
            this.callbackOk()
        }
        this.dialog = false
        this.$emit('ok')
    }

    onCancel () {
        this.dialog = false
        this.$emit('cancel')
    }

  }
  